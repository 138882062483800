<!-- 出票 -->
<template>
  <div>
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>首页</el-breadcrumb-item>
      <el-breadcrumb-item v-if="type == '1'">影厅管理</el-breadcrumb-item>
      <el-breadcrumb-item v-if="type == '9'">影厅列表</el-breadcrumb-item>
    </el-breadcrumb>

    <!-- 卡片视图 -->

    <el-card>
      <!-- 搜索框 -->
      <el-row :gutter="20">
        <el-col :span="9">
          <div>
            <el-input
              placeholder="搜索关键字"
              v-model="queryInfo.searchStr"
              clearable
              @clear="getScreenList"
            >
              <el-button
                slot="append"
                icon="el-icon-search"
                @click="getScreenList"
              ></el-button>
            </el-input>
          </div>
        </el-col>
        <!-- 添加放映厅 -->
        <el-col :span="6" v-if="type == '1'">
          <el-button @click="addDialogVisible = true" type="primary"
            >添加演出厅</el-button
          >
        </el-col>
      </el-row>

      <!-- 展示列表 -->
      <el-row :gutter="20">
        <el-col :span="4" v-for="item in screenList" :key="item.ID">
          <el-card :body-style="{ padding: '0px' }" class="showcard">
            <div style="padding: 7px">
              <span id="screentitle" @click="goToNewPage(item.ID)">{{
                item.名称
              }}</span>
              <hr />
              <div class="setstatus">
                <div>
                  <span id="green"></span>
                  {{ item.可用座位数 }}
                </div>
                <div>
                  <span id="red"></span>
                  {{ item.不可用座位数 }}
                </div>
                <div>
                  <span id="grey"></span>
                  {{ item.不显示座位数 }}
                </div>
              </div>
            </div>
          </el-card>
            <!-- {{scope.row}} -->
            <div class="setstatus" v-if="type == '1'">

              <el-tooltip
                v-if="item.状态 == '1'"
                class="item"
                effect="dark"
                content="禁用"
                placement="top"
                :enterable="false"
              >
                <el-button
                  type="warning"
                  icon="el-icon-close"
                  size="small"
                  circle
                  @click="disabled(item)"
                ></el-button>
              </el-tooltip>

              <el-tooltip
                v-if="item.状态 == '2'"
                class="item"
                effect="dark"
                content="启用"
                placement="top"
                :enterable="false"
              >
                <el-button
                  type="success"
                  icon="el-icon-check"
                  size="small"
                  circle
                  @click="enabled(item)"
                ></el-button>
              </el-tooltip>
            </div>
        </el-col>
      </el-row>

      <!-- 分页 -->

      <el-pagination
        @current-change="handleCurrentChange"
        :current-page="queryInfo.currentPage"
        :page-size="queryInfo.pageSize"
        layout="total, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>

      <!-- 添加影厅对话框 -->
      <el-dialog
        title="添加影厅"
        :visible.sync="addDialogVisible"
        width="50%"
        @closed="addHandleClose"
      >
        <el-form
          :model="addForm"
          :rules="addFormRules"
          ref="addFormRef"
          label-width="100px"
        >
          <el-form-item label="影厅名称" prop="名称">
            <el-input v-model="addForm.名称"></el-input>
          </el-form-item>
          <el-form-item label="区域名称" prop="区域名">
            <el-input v-model="addForm.区域名"></el-input>
          </el-form-item>
          <el-form-item label="影厅行数" prop="row">
            <el-input v-model="addForm.row"></el-input>
          </el-form-item>
          <el-form-item label="影厅列数" prop="col">
            <el-input v-model="addForm.col"></el-input>
          </el-form-item>
          <el-form-item label="是否禁用">
            <el-radio v-model="addForm.状态" label="1">启用</el-radio>
            <el-radio v-model="addForm.状态" label="2">禁用</el-radio>
          </el-form-item>
        </el-form>
        <span slot="footer">
          <el-button @click="addDialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="addScreen()">确 定</el-button>
        </span>
      </el-dialog>
    </el-card>
  </div>
</template>

<script>
export default {
  name: "screens",
  data() {
    return {
      queryInfo: {
        searchStr: "",
        // 当前的页数
        currentPage: 1,
        pageSize: 12,
      },
      //影厅总条数
      total: 0,
      type: this.$route.query.type || '1',
      //影厅列表
      screenList: [],
      // 添加影厅弹框是否展示
      addDialogVisible: false,
      //添加影厅数据
      addForm: {
        名称: "",
        区域名: "",
        row: "",
        col: "",
        状态: "1",
      },
      //添加数据验证规则
      addFormRules: {
        名称: [{ required: true, message: "请输入影厅名称", trigger: "blur" }],
        区域名: [{ required: true, message: "请输入区域名称", trigger: "blur" }],
        row: [
          { required: true, message: "请输入座位行数", trigger: "blur" },
          {
            type: "number",
            message: "请输入数字格式",
            trigger: "blur",
            transform(value) {
              return Number(value);
            },
          },
        ],
        col: [
          { required: true, message: "请输入座位列数", trigger: "blur" },
          {
            type: "number",
            message: "请输入数字格式",
            trigger: "blur",
            transform(value) {
              return Number(value);
            },
          },
        ],
        状态: [{}],
      },
    };
  },
  created() {
    console.log('type', this.type)
    this.getScreenList();
  },
  computed: {},
  methods: {
    getScreenList() {
      this.$axios({
        method: 'get',
        url: "/manager/hallList",
        params: this.queryInfo,
      })
        .then((res) => {
          // console.log(res.data);
          this.screenList = res.data;
          this.total = res.total;
        })
        .catch((err) => err);
    },
    //页码改变函数
    handleCurrentChange(newPage) {
      this.queryInfo.currentPage = newPage;
      this.getScreenList();
    },
    //关闭添加影厅弹框
    addHandleClose() {
      this.$refs.addFormRef.resetFields();
    },
    //添加影厅
    addScreen() {
      // console.log(this.addForm.status);
      this.$refs.addFormRef.validate((valid) => {
        if (!valid) {
          return;
        }
        
        const loading = this.$loading({
          lock: true,
          text: 'Loading',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });
        this.$axios({
          method: "post",
          url: "/manager/addHall",
          data: this.addForm,
        })
          .then((res) => {
            // console.log(res);
            loading.close()
            if (res.code == 200) {
              this.$message.success("添加成功");
              this.addDialogVisible = false;
              this.getScreenList();
            } else {
              this.$message.warning(res.msg);
              this.addForm.名称 = "";
            }
          })
          .catch((err) => {
            console.log(err.message)
          });
      });
    },
    enabled(item) {
      item.状态 = '1'
      this.editStatus(item)
    },
    disabled(item) {
      item.状态 = '2'
      this.editStatus(item)
    },
    editStatus(item) {
      console.log(item)
      const isClosed = item.状态 == '1' ? '启用' : '禁用'
      // 弹框
      this.$confirm("是否" + isClosed + "该演出厅", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          const loading = this.$loading({
            lock: true,
            text: 'Loading',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.7)'
          });
          const data = await this.$axios({
            method: "post",
            url: "/manager/editHall",
            data: item,
            headers: {
              "Content-Type": "application/json",
            },
          });

          loading.close()
          if (data.code == 200) {
            this.$message({
              type: "success",
              message: isClosed + "成功!",
            });
            // 刷新列表
            this.getScreenList();
          } else {
            item.状态 = item.状态 == '1' ? '2' : '1'
            this.$message.warning(res.msg)
          }
        })
        .catch(() => {
          item.状态 = item.状态 == '1' ? '2' : '1'
          this.$message({
            type: "info",
            message: "已取消" + isClosed,
          });
        });
    },
    //路由跳转
    goToNewPage(id) {
      let path = "/message/detalied"
      if (this.type == '9') {
        path = "/message/sellDetail"
      }
      this.$router.push({
        path,
        query: {
          id,
        },
      });
    },
  },
};
</script>

<style scoped>
.showcard {
  margin: 15px;
}
#screentitle {
  display: block;
  margin: 5px auto;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
}
#screentitle:hover {
  cursor: pointer;
}
#screentitle:hover {
  color: #409eff;
}
hr {
  margin: 10px 0px;
}
.setstatus {
  text-align: center;
}
.setstatus span {
  display: inline-block;
  width: 15px;
  height: 15px;
  border-radius: 50%;
}
.setstatus div {
  font-size: 14px;
  margin: 5px;
}
#green {
  background-color: #67c23a;
}
#grey {
  background-color: #8c939d;
}
#red {
  background-color: #f56c6c;
}
</style>